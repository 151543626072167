@import '../../styles/config.scss';

.upload-photo{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    position: relative;
    gap: 7px * $torem;

    .selectPhoto{
        width: 100px * $torem;
        height: 100px * $torem;
        border-radius: 15px * $torem;
        position: relative;
        overflow: hidden;
        cursor: pointer;

        svg{
            width: 100%;
            height: 100%;
        }

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

        .del{
            position: absolute;
            top: 8px * $torem;
            right: 8px * $torem;
            width: 15px * $torem;
            height: 15px * $torem;

            svg{
                width: 100%;
                height: 100%;
                fill: var(--static-white-color);
            }
        }
    }
}