@import '../../styles/config.scss';

.content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: inherit;

    h1{
        font-weight: 700;
        font-size: 24px * $torem;
        text-align: center;
        margin-bottom: 19px * $torem;
        line-height: 110%;
    }

    p{
        font-weight: 400;
        font-size: 16px * $torem;
        text-align: center;
        line-height: 110%;
        margin-bottom: 30px * $torem;
        word-break: keep-all;
    }

    .InputFromUI{

        .select-comp {
            .select-component__control{
                background-color: var(--cancel-bg-color);
            }   
        }
    }

    .ButtonOne{
        width: 100%;
        height: 57px * $torem;
        margin-bottom: 10px * $torem;
    }
}