@import '../config.scss';

.participants-list{
    width: 100%;
    background-color: var(--main-bg-color);
    padding: 19px * $torem 30px * $torem;
    box-sizing: border-box;

    .part-search{
        display: flex;
        align-items: center;
        width: 100%;
        height: 35px * $torem;
        padding: 0 0 0 43px * $torem;
        box-sizing: border-box;
        position: relative;
        border-radius: 100px * $torem;
        background-color: var(--secondary-bg-color);
        margin-bottom: 10px * $torem;
        overflow: hidden;

        &::after{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 16px * $torem;
            height: 35px * $torem;
            margin-left: 20px * $torem;
            background-image: url(../../images/search.png);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }

        .search{
            width: 100%;
            font-family: 'Circe';
            font-weight: 400;
            font-size: 14px * $torem;
            background-color: transparent;
            color: var(--main-text-color);
            outline: none;
            border: 0;
            box-sizing: border-box;
            padding: 0;

            &::placeholder{
                color: var(--placeholde-color);
            }
        }
    }

    .chips_container {
        display: flex;
        align-items: center;
        overflow: auto;
        width: 100%;
        box-sizing: border-box;
        top: 2rem;
        z-index: 2;
        transform: translate3d(0, 0, 0);
        margin-bottom: 10px * $torem;
        &::-webkit-scrollbar {
            display: none;
        }
        .chips_item {
            background: var(--secondary-bg-color);
            border-radius: 3rem;
            font-weight: 700;
            font-size: 1.6rem;
            line-height: 130%;
            padding: 0.6rem 1.5rem 0.7rem 1.5rem;
            margin-right: 0.5rem;
            color: var(--main-text-color);
            white-space: nowrap;

            &.active {
                background: var(--apply-bg-color);
                color: var(--apply-text-color);
            }
        }
    }


    .part-list{
        display: flex;
        flex-direction: column;
        gap: 15px * $torem;

        .NoGroup{
            font-size: 24px * $torem;
            text-align: center;
        }
    }
}