@import '../../styles/config.scss';

.toggle-switch {
    position: relative;
    display: inline-block;
    width: 40px * $torem;
    height: 24px * $torem;
    margin-right: 10px * $torem;

    .toggle-input {
        display: none;
    }

    .toggle-label {
        position: absolute;
        top: 0;
        left: 0;
        width: 40px * $torem;
        height: 24px * $torem;
        background-color: var(--cancel-text-color);
        border-radius: 34px * $torem;
        cursor: pointer;
        transition: background-color 0.3s;

        &::before {
            content: "";
            position: absolute;
            width: 20px * $torem;
            height: 20px * $torem;
            border-radius: 50%;
            top: 2px * $torem;
            left: 2px * $torem;
            background-color: var(--static-white-color);
            box-shadow: 0px 2px * $torem 5px * $torem 0px rgba(0, 0, 0, 0.3);
            transition: transform 0.3s;
        }
    }

    .toggle-input:checked + .toggle-label {
        background-color: var(--apply-bg-color);
    }

    .toggle-input:checked + .toggle-label::before {
        transform: translateX(16px * $torem);
    }
}