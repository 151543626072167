@import '../../styles/config.scss';

.ButtonTwo{
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Circe';
    background-color: var(--apply-bg-color);
    color: var(--apply-text-color);
    padding: 10px * $torem;
    border-radius: 5px * $torem;
    font-size: 16px * $torem;
    font-weight: 700;
    position: relative;
    overflow: hidden;
    transform: translateZ(0);
    transition: 300ms;

    &:disabled{
        opacity: 0.5;
        cursor: not-allowed;
        user-select: none;
    }

    .Ripple{
        span{
            background-color: var(--secondary-bg-color);
            animation-duration: 1500ms;
        }
    }
}