@import '../../styles/config.scss';

.InputFromUI{
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 75px * $torem;

    .title{
        font-weight: 400;
        font-size: 16px * $torem;
        line-height: 130%;
        color: var(--main-text-color);
        margin-bottom: 5px * $torem;
        max-width: 95%;

        .req{
            color: red;
        }
    }

    .textInput {
        width: 100%;
        height: 48px * $torem;
        background: var(--secondary-bg-color);
        color: var(--main-text-color);
        border-radius: 7px * $torem;
        padding: 10px * $torem 20px * $torem;
        box-sizing: border-box;
        font-family: 'Circe';
        font-weight: 400;
        font-size: 16px * $torem;
        line-height: 130%;
        outline: none;
        border: 0px * $torem solid red;
        transition: 400ms;

        &::placeholder{
            color: var(--placeholde-color);
        }

        &.error{
            border: 1px * $torem solid red;
            transition: 200ms;
        }
    }

    .textInputRow{
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        height: 48px * $torem;
        min-height: 96px * $torem;
        background: var(--secondary-bg-color);
        color: var(--main-text-color);
        border-radius: 7px * $torem;
        padding: 10px * $torem 20px * $torem;
        box-sizing: border-box;
        font-family: 'Circe';
        font-weight: 400;
        font-size: 16px * $torem;
        line-height: 130%;
        outline: none;
        border: 0px * $torem solid red;
        transition: 400ms;

        &::placeholder{
            color: var(--placeholde-color);
        }

        &.error{
            border: 1px * $torem solid red;
            transition: 200ms;
        }
    }

    .dateInput {
        -webkit-appearance: none;
        position: relative;

        &::-webkit-calendar-picker-indicator {
            display: none;
        }
    }

    .select-comp{
        width: 100%;
        line-height: 130%;
        outline: none;
        border: 0px * $torem solid red;
        border-radius: 7px * $torem;
        transition: 300ms;

        &.error{
            border: 1px * $torem solid red; 
        }

        &::placeholder{
            color: var(--placeholde-color);
        }

        .select-component__control{
            width: 100%;
            min-height: 48px * $torem;
            padding: 10px * $torem 20px * $torem;
            background: var(--secondary-bg-color);
            color: var(--main-text-color);
            border-radius: 7px * $torem;
            box-sizing: border-box;
            font-family: 'Circe';
            font-weight: 400;
            font-size: 16px * $torem;
            outline: none;
            border: none;
            box-shadow: none;

            .select-component__value-container{
                width: 100%;
                height: 100%;
                padding: 0;

                .select-component__input-container{
                    color: var(--main-text-color);
                }

                .select-component__multi-value{
                    background-color: var(--apply-bg-color);
                    padding: 3px * $torem;
                    padding-left: 6px * $torem;
                    border-radius: 2px * $torem;
                    margin: 2px * $torem;

                    .select-component__multi-value__label{
                        color: var(--apply-text-color);
                        border-radius: 2px * $torem;
                        font-size: 12px * $torem;
                        padding: 3px * $torem;
                        padding-left: 6px * $torem;
                    }
    
                    .select-component__multi-value__remove{
                        border-radius: 2px * $torem;
                        padding-left: 4px * $torem;
                        padding-right: 4px * $torem;
                        cursor: pointer;
                        background-color: transparent;
    
                        svg{
                            height: 14px * $torem;
                            width: 14px * $torem;
                            fill: var(--main-bg-color);
                            stroke: var(--main-bg-color);
                        }
                    }
                }

                .select-component__single-value{
                    color: var(--main-text-color);
                    margin-left: 2px * $torem;
                    margin-right: 2px * $torem;
                    height: 100%;
                    display: flex;
                    align-items: center;
                }

                .select-component__input-container{
                    margin: 0;
                    padding-bottom: 0;
                    padding-top: 0;
                }
            }

            .select-component__indicators{
                display: none;
            }
        }

        .select-component__menu{
            top: inherit;
            bottom: 100%;
            font-family: 'Circe';
            font-weight: 400;
            font-size: 16px * $torem;
            background-color: var(--secondary-bg-color);
            border-radius: 7px * $torem;
            overflow: hidden;
            border: 2px * $torem solid var(--apply-bg-color);
            box-sizing: border-box;
            margin-bottom: 8px * $torem;
            margin-top: 8px * $torem;
            box-shadow: none;

            .select-component__menu-list{
                padding: 0;
                margin: 0;
                max-height: 300px * $torem;

                &::-webkit-scrollbar{
                    width: 5px * $torem;
                    background-color: transparent;
                    padding: 3px;
                }

                &::-webkit-scrollbar-thumb{
                    background-color: var(--apply-bg-color);
                    opacity: 0.5;
                    border-radius: 30px * $torem;
                }

                .select-component__option{
                    padding: 8px * $torem 12px * $torem;

                    &:hover{
                        color: var(--apply-text-color);
                        background-color: var(--apply-bg-color); 
                    }
                }

                .select-component__option--is-selected{
                    background-color: var(--apply-bg-color);
                    color: var(--apply-text-color);
                }

                .select-component__option--is-focused{
                    background-color: var(--apply-bg-color);
                    color: var(--apply-text-color);
                }
            }
        }
    }

    .dateWithIcon{
        display: flex;
        width: 100%;
        height: 48px * $torem;
        background: var(--secondary-bg-color);
        color: var(--main-text-color);
        border-radius: 7px * $torem;
        border: 0px * $torem solid red;
        transition: 300ms;
        box-sizing: border-box;

        &.error{
            border: 1px * $torem solid red;
        }

        input{
            width: 100%;
            color: var(--main-text-color);
            background: transparent;
            padding: 10px * $torem 20px * $torem;
            box-sizing: border-box;
            font-family: 'Circe';
            font-weight: 400;
            font-size: 16px * $torem;
            line-height: 100%;
            outline: none;
            border: none;
            display: flex;
            align-items: center;
        }

        svg{
            width: 16px * $torem;
            height: auto;
            margin: 0 18px * $torem;
        }
    }

    .errorText{
        color: red;

        P{
            font-size: 12px * $torem;
            line-height: 130%;
            font-weight: 400;
        }
    }

    &.loading{
        .load-form{
            width: 100%;
            height: 48px * $torem;
            opacity: 0.3;

            span{
                width: 100%;
                height: 100%;
                border-radius: 7px * $torem;
            }
        }
    }
}