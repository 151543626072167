@import '../../styles/config.scss';

.part-card{
    width: 100%;
    overflow: hidden;
    background-color: var(--secondary-bg-color);
    padding: 16px * $torem;
    box-sizing: border-box;
    border-radius: 3px * $torem;

    .profile-card{
        display: flex;
        margin-bottom: 15px * $torem;

        .avatar{
            width: 70px * $torem;
            height: 70px * $torem;
            min-width: 70px * $torem;
            min-height: 70px * $torem;
            overflow: hidden;
            border-radius: 50%;
            margin-right: 15px * $torem;

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

        .short-info{
            display: flex;
            flex-direction: column;
            color: var(--main-text-color);
            font-weight: 400;
            gap: 3px * $torem;
            width: 100%;

            .name{
                font-size: 20px * $torem;
                line-height: 130%;
            }

            .skills{
                font-size: 14px * $torem;
                .info{
                    color: var(--apply-text-color);
                    background-color: var(--apply-bg-color);
                    border-radius: 1rem;

                    margin-left: -4px * $torem;
                    padding-left: 4px * $torem;
                    padding-right: 4px * $torem;
                    padding-bottom: 3px * $torem;
                    padding-top: 4px * $torem;

                    width: fit-content;
                    display: flex;
                    align-items: center;
                    line-height: 100%;
                }
            }

            .city{
                font-size: 14px * $torem;
                opacity: 0.3;
            }
        }
    }

    .info{
        display: flex;
        flex-direction: column;
        gap: 14px * $torem;

        .info-block{
            font-weight: 400;
            font-size: 14px * $torem;
            color: var(--main-text-color);
            line-height: 130%;

            .name-category{
                text-transform: uppercase;
                font-size: 10px * $torem;
                opacity: 0.3;
            }
        }
    }
}