@import '../config.scss';

.cid{
    display: flex;
    width: 100%;
    height: 100vh;
    font-family: 'Circe';
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    font-size: 16px * $torem;

    .cid_info{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        padding: 50px * $torem 30px * $torem;
        box-sizing: border-box;

        .ci-p{
            width: 100px * $torem;
            height: auto;
            margin-bottom: 20px * $torem;

            .ci-primary{
                fill:var(--main-text-color);

                &.animate{
                    fill:var(--apply-bg-color);
                }
            }
        }

        p{
            text-align: center;
            font-size: 16px * $torem;

            span{
                font-size: 50px * $torem;
                font-weight: 700;
                line-height: 100%;
            }
        }
    }
}