@import '../config.scss';

.profile-edit{
    display: flex;
    flex-direction: column;
    align-items: center;

    .edit-form{
        width: 100%;
        border-radius: 20px * $torem;
        background-color: var(--main-bg-color);
        padding: 19px * $torem 30px * $torem;
        box-sizing: border-box;
        margin-bottom: 15px * $torem;
        display: flex;
        flex-direction: column;
        gap: 25px * $torem;

        .forms{
            display: flex;
            flex-direction: column;
            gap: 25px * $torem;
        }

        .groupName{
            font-size: 20px * $torem;
            padding-top: 30px *$torem;
            border-top: 1px * $torem solid var(--placeholde-color);
            color: var(--main-text-color);
            text-align: left;
            font-weight: 700;
            box-sizing: border-box;
        }

        .areToImpulse{
            display: flex;
            font-size: 16px * $torem;

            .toggle-switch{
                margin-right: 20px * $torem;
            }

            p{
                width: 100%;
            }
        }

        .InputFromUI{
            span{
               opacity: 0.3; 
            }
        }

        .input-avatar{
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 16px * $torem;
            .avatar-small {
                width: 370px * $torem !important;
                height: 80px * $torem !important;
            }
            .avatar{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 370px * $torem;
                height: 170px * $torem;
                background: var(--secondary-bg-color);
                border-radius: 12px * $torem;
                overflow: hidden;
                cursor: pointer;

                svg{
                    width: 75px * $torem;
                    height: 75px * $torem;
                }

                .faicon{
                    width: 30px * $torem;
                    height: 30px * $torem;
                }
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }
        }
    }
    hr {
        width: 100%;
    }
    .ButtonOne{
        width: 315px * $torem;
        height: 57px * $torem;
        font-size: 16px * $torem;
    }
    .ButtonTwo{
        width: 315px * $torem;
        height: 57px * $torem;
        font-size: 16px * $torem;
        margin-bottom: 40px * $torem;
    }
}