$torem: 2.048 * (1rem / 16px);

@mixin _768 {
  @media screen and (max-width: 768px) {
    @content;
  }
}

// Цвета интерфейса (Пока только в темном стиле)

html[data-theme='dark'] {
  --main-text-color: #FFFFFF; // При смене темы меняем.
  --main-bg-color: #000;  // При смене темы меняем. Основной цвет фона,
                          // может использоваться как bg для фона, так и для каких-либо объектов
  --secondary-bg-color: #1D2122;  // При смене темы меняем. Второстепенный цвет фона
  --apply-bg-color: #0979ff;
  --apply-text-color: #ffffff;
  --cancel-bg-color: #141414; // При смене темы меняем
  --cancel-text-color: #9c9c9c; // При смене темы меняем
  --static-white-color: #FFFFFF; // Использовать только в местах, где цвет будет таким независимо от темы
  --box-shadow: rgba(0,0,0,0.75);
  --popup-box-shadow: rgba(255, 255, 255, 0.3);
  --popup-behind-bg-color: rgba(0, 0, 0, 0.5);
  --card-swipe-elem-bg: linear-gradient(180deg, rgba(0,231,134,0) 60%, rgba(0,0,0,0.5) 75%); // При смене темы меняем

  --placeholde-color: #858585;
}


html[data-theme='light'] {
  --main-text-color: #000; // При смене темы меняем.
  --main-bg-color: #FFFFFF; // При смене темы меняем. Основной цвет фона,
                            // может использоваться как bg для фона, так и для каких-либо объектов
  --secondary-bg-color: #f1f1f1; // При смене темы меняем. Второстепенный цвет фона
  --apply-bg-color: #0979ff;
  --apply-text-color: #ffffff;
  --cancel-bg-color: #c8c8c8; // При смене темы меняем
  --cancel-text-color: #6f6f6f; // При смене темы меняем
  --static-white-color: #FFFFFF;
  --box-shadow: rgba(0,0,0,0.75);
  --popup-box-shadow: rgba(0, 0, 0, 0.3);
  --popup-behind-bg-color: rgba(0, 0, 0, 0.5);
  --card-swipe-elem-bg: linear-gradient(180deg, rgba(0,231,134,0) 60%, rgba(0,0,0,0.5) 75%); // При смене темы меняем

  --placeholde-color: #858585;
}


html[data-theme='tg-dark'] {
  --main-text-color: var(--tg-theme-text-color);
  --main-bg-color: var(--tg-theme-bg-color);
  --secondary-bg-color: var(--tg-theme-secondary-bg-color);
  --apply-bg-color: #0979ff;
  --apply-text-color: #ffffff;
  --cancel-bg-color: #141414; // При смене темы меняем
  --cancel-text-color: #9c9c9c; // При смене темы меняем
  --static-white-color: #FFFFFF;
  --box-shadow: rgba(0,0,0,0.75);
  --popup-box-shadow: rgba(255, 255, 255, 0.1);
  --popup-behind-bg-color: rgba(0, 0, 0, 0.5);
  --card-swipe-elem-bg: linear-gradient(180deg, rgba(0,231,134,0) 60%, rgba(0,0,0,0.5) 75%); // При смене темы меняем

  --placeholde-color: #858585;
}

html[data-theme='tg-light'] {
  --main-text-color: var(--tg-theme-text-color);
  --main-bg-color: var(--tg-theme-bg-color);
  --secondary-bg-color: var(--tg-theme-secondary-bg-color);
  --apply-bg-color: #0979ff;
  --apply-text-color: #ffffff;
  --cancel-bg-color: #c8c8c8; // При смене темы меняем
  --cancel-text-color: #6f6f6f; // При смене темы меняем
  --static-white-color: #FFFFFF;
  --box-shadow: rgba(0,0,0,0.75);
  --popup-box-shadow: rgba(0, 0, 0, 0.3);
  --popup-behind-bg-color: rgba(0, 0, 0, 0.5);
  --card-swipe-elem-bg: linear-gradient(180deg, rgba(0,231,134,0) 60%, rgba(0,0,0,0.5) 75%); // При смене темы меняем

  --placeholde-color: #858585;
}
