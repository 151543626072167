@import '../../styles/config.scss';

.tinderContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    min-height: 100vh;
    max-height: 100vh;
    box-sizing: border-box;

    .cardContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 315px * $torem;
        height: 522px * $torem;
        position: relative;

        @include _768{
            height: 79.6946564885vh;
            max-height: 504px * $torem;
        }

        .cardSwipe{
            width: 100%;
            height: 504px * $torem;
            overflow: hidden;
            position: absolute;
            bottom: 0;

            @include _768{
                height: 76.9465648855vh;
                max-height: 504px * $torem;
            }

            .cardSwipeElem{
                width: 100%;
                height: 100%;
                border-radius: 10px * $torem;
                background-size: cover;
                background-position: top;
                overflow: hidden;
                transform: translateZ(0) !important;

                &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: var(--card-swipe-elem-bg);
                    z-index: 0;
                }
        
                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(255, 238, 47, 0.5);
                    z-index: 1;
                    clip-path: circle(0%);
                    transition: 300ms;
                    z-index: 10;
                }

                .icon-select{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    width: 48px * $torem;
                    height: 48px * $torem;
                    top: 18px * $torem;
                    z-index: 11;
                    opacity: 0;
                    transition: 100ms;

                    &.like{
                        left: 19px * $torem;
                    }

                    &.dislike{
                        right: 19px * $torem;
                    }

                    svg{
                        width: 100%;
                        height: auto;
                    }

                    &.active{
                        opacity: 0;
                    }
                }

                .likeYou{
                    position: absolute;
                    top: 20px * $torem;
                    left: 20px * $torem;
                    gap: 25px * $torem;
                    
                    .request{
                        width: 90%;
                    }
                }

                .card-info{
                    display: flex;
                    flex-direction: column;
                    justify-content: end;
                    position: relative;
                    font-size: 16px * $torem;
                    width: 100%;
                    height: 100%;
                    padding: 20px * $torem;
                    box-sizing: border-box;
                    color: var(--static-white-color);
                    font-weight: 400;
                    line-height: 130%;

                    .name{
                        font-weight: 700;
                        font-size: 24px * $torem;
                    }

                    .city{
                        opacity: 0.7;
                    }

                    .desc{
                        margin-top: 9px * $torem;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                    }
                }
            }
        }

        .NothingCard{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            position: relative;
            font-size: 25px * $torem;
            width: 100%;
            height: 100%;
            color: var(--main-text-color);
            user-select: none;

            p{
                text-align: center;
                margin-bottom: 15px * $torem;
            }

            .ButtonTwo{
                height: 30px * $torem;
                margin-bottom: 15px * $torem;

                svg{
                    width: 20px * $torem;
                    height: 20px * $torem;
                    fill: var(--apply-text-color);

                    &.rotate{
                        animation: rotate-arrow 1s infinite;
                    }

                    @keyframes rotate-arrow {
                        0%{
                            transform: rotate(360deg);
                        }
                        50%{
                            transform: rotate(180deg);
                        }
                        100%{
                            transform: rotate(0deg);
                        }
                    }
                }
            }

            .loader {
                display: block;
                width: 60px * $torem;
                height: 60px * $torem;

                .loader__ring, .loader__ball {
                    animation: ring 2s ease-out infinite;
                    stroke: var(--apply-bg-color);
                    stroke-width: 20px * $torem;
                }
                
                .loader__ball {
                    animation-name: ball;
                }
            }

            @keyframes ring {
                from {
                    stroke-dasharray: 0 257 0 0 1 0 0 258;
                }
                25% {
                    stroke-dasharray: 0 0 0 0 257 0 258 0;
                }
                50%, to {
                    stroke-dasharray: 0 0 0 0 0 515 0 0;
                }
            }
            
            @keyframes ball {
                from, 50% {
                    animation-timing-function: ease-in;
                    stroke-dashoffset: 1;
                }
                64% {
                    animation-timing-function: ease-in;
                    stroke-dashoffset: -109;
                }
                78% {
                    animation-timing-function: ease-in;
                    stroke-dashoffset: -145;
                }
                92% {
                    animation-timing-function: ease-in;
                    stroke-dashoffset: -157;
                }
                57%, 71%, 85%, 99%, to {
                    animation-timing-function: ease-out;
                    stroke-dashoffset: -163;
                }
            }
        }
    }

    .buttons {
        padding: 40px * $torem 0 20px * $torem 0;
        width: 315px * $torem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .ButtonOne, .ButtonTwo{
            width: 140px * $torem;
            height: 38px * $torem;

            .arrow-move{
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 380px * $torem;
                animation: arrow-right 3s linear infinite;
                z-index: -1;

                svg{
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 190px * $torem;
                    height: 100%;

                    path{
                        fill: #9df4ff;
                        fill-opacity: 0.5;
                    }
                }
            }

            &.setting{
                width: 15px * $torem;

                svg{
                    width: 20px * $torem;
                    height: 20px * $torem;

                    path{
                        fill: var(--cancel-text-color);
                    }
                }
            }
        }

        .ButtonOne{
            color: var(--cancel-text-color);

            .arrow-move{
                animation: arrow-left 3s linear infinite;
                right: 0;
                left: auto;
    
                svg{
                    right: 0;
                    left: auto;

                    path{
                        fill: #595959;
                        fill-opacity: 0.5;  
                    }
                }
            }
        }

        @keyframes arrow-right {
            from {
                transform: translateX(-190px * $torem);
            }
            to {
                transform: translateX(190px * $torem);
            }
        }

        @keyframes arrow-left {
            from {
                transform: translateX(190px * $torem);
            }
            to {
                transform: translateX(-190px * $torem);
            }
        }
    }
}