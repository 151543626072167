@import './reset.scss';
@import './config.scss';
@import './fonts/font.scss';

html{
    
    font-size: 0.5208333333vw;

    @include _768{
        font-size: 2.0833333333vw;
    }
}

body{
    font-family: 'Circe';
    background-color: var(--apply-bg-color);
    color: var(--main-text-color);

    &::-webkit-scrollbar {
        width: 0 !important;             
    }
}

#root{
    width: 100%;
    height: 100%;
}

.AppView{
    width: 100%;
    height: 100%;
    min-height: 100vh;
    margin: 0 auto;
    max-width: 375px * $torem;
    background-color: var(--main-bg-color);
    box-shadow: 0px 0px * $torem 100px * $torem 0px var(--box-shadow);

    .openFull{
        display: flex;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: transparent;
        opacity: 0;
        pointer-events: none;
        transition: 200ms;
        z-index: 9999;

        &::before{
            content: '';
            position: absolute;
            background: rgba(255, 255, 255, 0.01);
            backdrop-filter: blur(2.5px * $torem);
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0;
            transition: 200ms;
        }

        &.view{
            opacity: 1;
            pointer-events: all;

            &::before{
                opacity: 1;
            }
        }

        .infoBlock{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            user-select: none;
            z-index: 100;
            margin-top: 60px * $torem;

            svg{
                width: 62px * $torem;
                height: 100%;

                path{
                    fill: var(--main-text-color);
                }
                
                .path1, .path2, .path3 {
                    fill-opacity: 0.15;
                    animation: opacity 1500ms ease-in-out infinite;
                }

                .path1{
                    animation-delay: 1500ms;
                }

                .path2 {
                    animation-delay: 1000ms;
                }

                .path3 {
                    animation-delay: 750ms;
                }
            }
        }

        @keyframes opacity {
            0% {
                fill-opacity: 0.15;
            }
            50% {
                fill-opacity: 0.7;
            }
            100% {
                fill-opacity: 0.15;
            }
        }
    }

    .Toastify__toast-container{
        .Toastify__toast{
            background: var(--main-bg-color);
            color: var(--main-text-color);
            border: 2px * $torem solid var(--secondary-bg-color);
            box-sizing: border-box;
            font-size: 14px * $torem;
        }
    }

    .NothingView{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        font-size: 25px * $torem;
        width: 100%;
        height: 100%;
        min-height: 99vh;
        color: var(--main-text-color);
        user-select: none;

        p{
            text-align: center;
        }

        .loader {
            display: block;
            width: 60px * $torem;
            height: 60px * $torem;

            .loader__ring, .loader__ball {
                animation: ring 2s ease-out infinite;
                stroke: var(--apply-bg-color);
                stroke-width: 20px * $torem;
            }
            
            .loader__ball {
                animation-name: ball;
            }
        }

        @keyframes ring {
            from {
                stroke-dasharray: 0 257 0 0 1 0 0 258;
            }
            25% {
                stroke-dasharray: 0 0 0 0 257 0 258 0;
            }
            50%, to {
                stroke-dasharray: 0 0 0 0 0 515 0 0;
            }
        }
        
        @keyframes ball {
            from, 50% {
                animation-timing-function: ease-in;
                stroke-dashoffset: 1;
            }
            64% {
                animation-timing-function: ease-in;
                stroke-dashoffset: -109;
            }
            78% {
                animation-timing-function: ease-in;
                stroke-dashoffset: -145;
            }
            92% {
                animation-timing-function: ease-in;
                stroke-dashoffset: -157;
            }
            57%, 71%, 85%, 99%, to {
                animation-timing-function: ease-out;
                stroke-dashoffset: -163;
            }
        }
    }
}

.MuiSkeleton-root{
    background-color: var(--placeholde-color)!important;
}

.changePage-enter {
    opacity: 0;
}
.changePage-enter-active {
    opacity: 1;
    transition: opacity 200ms;
}
.changePage-exit {
    opacity: 1;
}
.changePage-exit-active {
    opacity: 0;
    transition: opacity 200ms;
}

.full_width {
    width: 100%;
}
.full_height {
    height: 100vh;
}