@import '../../styles/config.scss';

.content{
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1{
        font-weight: 700;
        font-size: 24px * $torem;
        text-align: center;
        margin-bottom: 19px * $torem;
        line-height: 110%;
    }

    p{
        font-weight: 400;
        font-size: 16px * $torem;
        text-align: center;
        line-height: 110%;
        margin-bottom: 22px * $torem;
    }

    .selectTimeContainer{
        display: flex;
        flex-direction: column;
        gap: 5px * $torem;
        margin-bottom: 42px * $torem;

        .selectTime{
            display: grid;
            grid-template-columns: 16px * $torem 1fr;
            grid-template-rows: auto;
            grid-gap: 7px * $torem;
            align-items: center;
            width: 100%;
            height: 35px * $torem;
            border-radius: 100px * $torem;
            background-color: var(--main-bg-color);
            color: var(--main-text-color);
            padding: 8px * $torem 20px * $torem;
            box-sizing: border-box;
            cursor: auto;

            svg{
                width: 16px * $torem;
                height: 17px * $torem;
            }

            span{
                font-family: 'Circe';
                font-weight: 400;
                font-size: 14px * $torem;
                line-height: 115%;
                text-align: left;
                display: flex;
                align-items: center;
                cursor: pointer;

            }

            input{
                background-color: transparent;
                border: 0;
                outline: none;
                color: var(--main-text-color);
                font-family: 'Circe';
                font-weight: 400;
                font-size: 14px * $torem;
                line-height: 115%;
                text-align: left;
                padding: 0;
                min-height: 90%;
                cursor: pointer;

                &::-webkit-calendar-picker-indicator {
                    background-color: transparent;
                    cursor: pointer;
                    filter: invert(1);
                }
            }
        }
    }

    .ButtonOne{
        width: 100%;
        height: 57px * $torem;
    }
}
