@import '../../styles/config.scss';

.content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: inherit;

    h1{
        font-weight: 700;
        font-size: 24px * $torem;
        text-align: center;
        margin-bottom: 19px * $torem;
        line-height: 110%;
    }

    p{
        font-weight: 400;
        font-size: 16px * $torem;
        text-align: center;
        line-height: 110%;
        margin-bottom: 30px * $torem;
    }

    // .selectMatchTime{
    //     display: flex;
    //     flex-direction: column;
    //     width: 100%;
    //     box-sizing: border-box;
    //     padding: 10px * $torem 10px * $torem 40px * $torem 10px * $torem;
    //     font-weight: 400;
    //     font-size: 14px * $torem;
    //     gap: 15px * $torem;

    //     .selectMatchTimeElem{
    //         display: flex;
    //         flex-direction: column;
    //         gap: 7px * $torem;

    //         .selectMatchTimeElemDate{
    //             color: var(--main-text-color);
    //             opacity: 0.5;
    //             line-height: 110%;
    //         }

    //         .selectMatchTimeElemDateElem{
    //             display: flex;
    //             flex-wrap: wrap;
    //             gap: 13px * $torem;

    //             .dateElem{
    //                 display: flex;
    //                 justify-content: center;
    //                 align-items: center;
    //                 box-sizing: border-box;
    //                 padding: 10px * $torem 15px * $torem;
    //                 color: var(--main-text-color);
    //                 background-color: var(--main-bg-color);
    //                 line-height: 80%;
    //                 border-radius: 100px * $torem;
    //                 cursor: pointer;
    //                 transition: 300ms;

    //                 &.select{
    //                     color: var(--apply-text-color);
    //                     background-color: var(--apply-bg-color);
    //                 }
    //             }
    //         }
    //     }
    // }

    .InputFromUI{
        span{
            display: none;
        }

        .select-comp {
            .select-component__control{
                background-color: var(--cancel-bg-color);
            }   
        }
    }

    .ButtonOne{
        width: 100%;
        height: 57px * $torem;
        margin-bottom: 10px * $torem;
    }
}