@import '../config.scss';

.evaluation{
    display: flex;
    flex-direction: column;
    gap: 15px * $torem;
    width: 100%;
    background-color: var(--main-bg-color);
    padding: 50px * $torem 30px * $torem;
    box-sizing: border-box;
    font-family: 'Circe';
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    font-size: 16px * $torem;
    min-height: 100vh;

    .avatars{
        display: flex;
        margin-bottom: 20px * $torem;

        .onePer, .twoPer{
            width: 80px * $torem;
            height: 80px * $torem;
            border-radius: 50%;
            overflow: hidden;
            box-sizing: border-box;
            position: relative;

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

        .twoPer{
            border: 2px * $torem solid var(--main-bg-color);
            left: -40px * $torem;
        }
    }

    .head-text{
        font-weight: 700;
        font-size: 24px * $torem;
        line-height: 110%;
    }

    .buttons{
        display: flex;
        justify-content: space-between;

        .ButtonOne{
            width: 155px * $torem;
            height: 57px * $torem;
            background-color: #4E4E4E;
            color: var(--static-white-color);
            transition: 200ms;

            &.active{
                background-color: var(--apply-bg-color);
                color: var(--apply-text-color);
            }
        }
    }

    .rate{
        display: flex;
        gap: 15px * $torem;
        animation: none;
        margin-bottom: 15px * $torem;

        svg{
            width: 42px * $torem;
            height: 40px * $torem;
            cursor: pointer;

            path{
                fill: #DCDCDC;
                transition: 300ms;
            }

            &.active{
                path{
                    fill: #FFF056;
                }
            }
        }
    }

    .review{
        display: flex;
        flex-direction: column;
        gap: 15px * $torem;
        b {
            color: var(--apply-bg-color)
        }
        .switch {
            display: flex;
            font-size: 16px * $torem;

            .toggle-switch{
                margin-right: 20px * $torem;
            }

            p{
                width: 100%;
            }
        }
        textarea{
            width: 100%;
            max-width: 100%;
            min-width: 100%;
            min-height: 230px * $torem;
            border: 0;
            outline: none;
            padding: 24px * $torem;
            background-color: var(--secondary-bg-color);
            border: 1px * $torem solid var(--placeholde-color);
            border-radius: 7px * $torem;
            box-sizing: border-box;
            font-family: 'Circe';
            font-style: normal;
            font-weight: 400;
            font-size: 16px * $torem;
            color: var(--main-text-color);

            &::placeholder{
                color: var(--placeholde-color);
            }
        }

        .ButtonOne{
            width: 100%;
            height: 57px * $torem;
            background-color: var(--placeholde-color);
            color: var(--static-white-color);

            &.publish{
                background-color: var(--apply-bg-color);
                color: var(--apply-text-color);
            }
        }

        .ButtonTwo{
            width: 100%;
            height: 57px * $torem;
        }
    }
}