@import '../../styles/config.scss';

.header{
    display: grid;
    grid-template-columns: 25px 1fr 25px;
    width: 100%;
    height: 51px * $torem;
    box-sizing: border-box;
    padding: 25px * $torem 30px * $torem 20px * $torem 30px * $torem;
    position: relative;


    .namePage{
        display: flex;
        justify-content: center;
        text-transform: uppercase;
        color: var(--main-bg-color);
        font-size: 20px * $torem;
        font-weight: 700;
        line-height: 130%;
        grid-column: 2;
    }

    .back{
        background-color: transparent;
        border: 0;
        padding: 0;
        cursor: pointer;
        grid-column: 1;

        svg{
            width: 25px * $torem;
            height: auto;

            path{
                fill: var(--main-text-color);
            }
        }
    }
}