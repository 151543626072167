@import '../../styles/config.scss';

.likeYou{
    display: flex;
    color: var(--main-text-color);
    gap: 11px * $torem;
    align-items: center;
    margin-left: 5px * $torem;

    .like{
        display: flex;
        align-items: center;

        svg{
            width: 45px * $torem;
            height: 45px * $torem;
            filter: drop-shadow(0px 4px * $torem 4px * $torem rgba(0, 0, 0, 0.25));
            
            path{
                fill: var(--main-text-color);
            }
        }
    }

    .request{
        font-weight: 400;
        font-size: 14px * $torem;
        line-height: 130%;
        width: 60%;
        text-shadow: 0px 2px * $torem 4px * $torem rgba(0, 0, 0, 0.5);
    }
}