@import '../../styles/config.scss';

.Popup{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; 
    background-color: var(--popup-behind-bg-color);
    opacity: 0;
    pointer-events: none;
    transition: 200ms;
    z-index: 999;

    &.show{
        opacity: 1;
        pointer-events: all;
    }

    .PopupContent{
        width: 315px * $torem;
        //height: 438px * $torem;
        height: auto;
        background-color: var(--secondary-bg-color);
        box-shadow: 0px 0px 24px * $torem var(--popup-box-shadow);
        border-radius: 20px * $torem;
        padding: 60px * $torem 22px * $torem 22px * $torem 22px * $torem;
        box-sizing: border-box;
        position: relative;
        .copyContainer{
            display: inherit;
            color: var(--main-text-color);
            &:hover {
                color: var(--apply-bg-color)
            }
            .copyIcon {
                svg {
                    height: 48px * $torem;
                    border-radius: 7px * $torem;
                    padding: 10px * $torem 20px * $torem;
                    box-sizing: border-box;
                    font-family: 'Circe';
                    font-weight: 400;
                    font-size: 16px * $torem;
                    line-height: 130%;
                    outline: none;
                    transition: 400ms;
                }
            }
            .copyInput {
                width: 77%;
                height: 48px * $torem;
                background: var(--main-bg-color);
                color: var(--main-text-color);
                border-radius: 7px * $torem;
                padding: 10px * $torem 20px * $torem;
                box-sizing: border-box;
                font-family: 'Circe';
                font-weight: 400;
                font-size: 16px * $torem;
                line-height: 130%;
                outline: none;
                border: 0px * $torem solid red;
                transition: 400ms;
            }
        }

        .close{
            position: absolute;
            right: 22px * $torem;
            top: 22px * $torem;
            cursor: pointer;
            transition: 250ms;

            &:hover{
                opacity: 0.5;
            }

            svg{
                width: 20px * $torem;
                height: 20px * $torem;

                rect{
                    width: 100%;
                    height: 100%; 
                }
            }
        }

        .content{
            display: flex;
            flex-wrap: wrap;
            word-break: break-all;
        }
    }
}